const logoutButtons = document.querySelectorAll('.logout-btn');

resetLocalStorage(logoutButtons);

function resetLocalStorage(buttons) {
  buttons.forEach(btn => {
    btn.addEventListener('click', () => {
      localStorage.removeItem('_campusreel_token');
    });
  })
}
